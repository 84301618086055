import {clone, repeat, times, uniq} from "ramda";

interface DepthFirstCallbacks<V> {
    enterVertex?: (args: {currentVertex: V; previousVertex?: V}) => void;
    leaveVertex?: (args: {currentVertex: V; previousVertex?: V}) => void;
    allowTraversal?: (args: {currentVertex: V; previousVertex?: V; nextVertex: V}) => boolean;
    getNeighbors: (currentVertex: V) => V[];
}

function initCallbacks<V>(callbacks: DepthFirstCallbacks<V>) {
    const allowTraversalCallbackGenerator = () => {
        const seen: V[] = [];
        return ({nextVertex}: {nextVertex: V}) => {
            if (!seen.includes(nextVertex)) {
                seen.push(nextVertex);
                return true;
            }
            return false;
        };
    };

    return {
        ...callbacks,
        allowTraversal: callbacks.allowTraversal ?? allowTraversalCallbackGenerator(),
    };
}
function depthFirstSearchRecursive<V>(
    currentVertex: V,
    previousVertex: V | undefined,
    callbacks: DepthFirstCallbacks<V>
) {
    callbacks.enterVertex?.({currentVertex, previousVertex});
    for (const nextVertex of callbacks.getNeighbors(currentVertex)) {
        if (callbacks.allowTraversal!({previousVertex, currentVertex, nextVertex})) {
            depthFirstSearchRecursive(nextVertex, currentVertex, callbacks);
        }
    }
    callbacks.leaveVertex?.({currentVertex, previousVertex});
}

export function depthFirstSearch<V>(startVertex: V, callbacks: DepthFirstCallbacks<V>) {
    depthFirstSearchRecursive(startVertex, undefined, initCallbacks(callbacks));
}

interface FindCycleCallbacks<V> {
    getNeighbors: (currentVertex: V) => V[];
}

export function detectCycle<V>(startVertex: V, callbacks: FindCycleCallbacks<V>): V[] | undefined {
    const visited: V[] = [];
    let cycle: V[] | undefined;
    depthFirstSearch(startVertex, {
        getNeighbors: callbacks.getNeighbors,
        enterVertex: ({currentVertex: v}) => {
            if (visited.includes(v)) {
                cycle = clone(visited);
            }
            visited.push(v);
        },
        leaveVertex: () => {
            visited.pop();
        },
        allowTraversal: () => !cycle,
    });
    return cycle;
}

// Tarjan's strongly connected components algorithm (https://en.wikipedia.org/wiki/Tarjan%27s_strongly_connected_components_algorithm)
export function getStronglyConnectedComponents(adjList: number[][]) {
    const numVertices = adjList.length;
    const index = repeat(-1, numVertices);
    const lowValue = repeat(0, numVertices);
    const active = repeat(false, numVertices);
    const child = repeat(0, numVertices);
    const scc = repeat(-1, numVertices);
    const sccLinks = times((): number[] => [], numVertices);

    // The strongConnect function
    let count = 0;
    const components: number[][] = [];
    const sccAdjList: number[][] = [];

    function strongConnect(v: number) {
        // To avoid running out of stack space, this emulates the recursive behaviour of the normal algorithm, effectively using T as the call stack.
        const S: number[] = [v],
            T: number[] = [v];
        index[v] = lowValue[v] = count;
        active[v] = true;
        count += 1;
        while (T.length > 0) {
            v = T[T.length - 1];
            const e = adjList[v];
            if (child[v] < e.length) {
                // If we're not done iterating over the children, first try finishing that.
                let i = child[v];
                for (; i < e.length; ++i) {
                    // Start where we left off.
                    const u = e[i];
                    if (index[u] < 0) {
                        index[u] = lowValue[u] = count;
                        active[u] = true;
                        count += 1;
                        S.push(u);
                        T.push(u);
                        break; // First recurse, then continue here (with the same child!).
                        // There is a slight change to Tarjan's algorithm here.
                        // Normally, after having recursed, we set lowValue like we do for an active child (although some variants of the algorithm do it slightly differently).
                        // Here, we only do so if the child we recursed on is still active.
                        // The reasoning is that if it is no longer active, it must have had a lowValue equal to its own index, which means that it is necessarily higher than our lowValue.
                    } else if (active[u]) {
                        lowValue[v] = Math.min(lowValue[v], lowValue[u]) | 0;
                    }
                    if (scc[u] >= 0) {
                        // Node v is not yet assigned an scc, but once it is that scc can apparently reach scc[u].
                        sccLinks[v].push(scc[u]);
                    }
                }
                child[v] = i; // Remember where we left off.
            } else {
                // If we're done iterating over the children, check whether we have an scc.
                if (lowValue[v] === index[v]) {
                    // TODO: It /might/ be true that T is always a prefix of S (at this point!!!), and if so, this could be used here.
                    const component: number[] = [];
                    const links: number[][] = [];
                    let linkCount = 0;
                    for (let i = S.length - 1; i >= 0; --i) {
                        const w = S[i];
                        active[w] = false;
                        component.push(w);
                        links.push(sccLinks[w]);
                        linkCount += sccLinks[w].length;
                        scc[w] = components.length;
                        if (w === v) {
                            S.length = i;
                            break;
                        }
                    }
                    components.push(component);
                    const allLinks = new Array(linkCount);
                    for (let i = 0; i < links.length; i++) {
                        for (let j = 0; j < links[i].length; j++) {
                            allLinks[--linkCount] = links[i][j];
                        }
                    }
                    sccAdjList.push(allLinks);
                }
                T.pop(); // Now we're finished exploring this particular node (normally corresponds to the return statement)
            }
        }
    }

    //Run strong connect starting from each vertex
    for (let i = 0; i < numVertices; ++i) {
        if (index[i] < 0) {
            strongConnect(i);
        }
    }

    for (let i = 0; i < sccAdjList.length; i++) {
        const e = sccAdjList[i];
        if (e.length > 0) {
            sccAdjList[i] = uniq(e).sort();
        }
    }

    return {components: components, adjacencyList: sccAdjList};
}
