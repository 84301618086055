import "./libs/wdyr";
import ReactDOM from "react-dom";
import "react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import "cropperjs/dist/cropper.css";
import "m-react-splitters/lib/splitters.css";
import "react-sortable-tree/style.css";
import {setAutoFreeze} from "immer";

import "./index.css";
import * as serviceWorker from "./serviceWorker";
import {initializeI18n} from "./libs/i18next";
import {setupYup} from "./libs/yup";

const promise: Promise<any> = initializeI18n();
setAutoFreeze(false);

promise
    .then(
        () => {
            setupYup();
            return import("./main"); // Don't load app until i18n is initialized
        },
        error => {
            ReactDOM.render(<div>{error.toString()}</div>, document.getElementById("root"));
        }
    )
    .finally(() => {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();
    });
