export const removeDiacritics = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const toUpperCamelCase = (str: string) => {
    const upper = str[0].toLocaleUpperCase();
    if (str[0] === upper) {
        return str;
    }
    const chars: number[] = new Array(str.length);
    chars[0] = upper.charCodeAt(0);
    for(let i = 1; i < str.length; i++) {
        chars[i] = str.charCodeAt(i);
    }
    return String.fromCharCode.apply(undefined, chars);
}

export const makeIdentifier = (str: string) => {
    let res = str.replaceAll(/-[a-z]/g, (substring: string) => {
        return substring[1].toUpperCase();
    });
    res = res.replaceAll(/[^0-9a-zA-Z_]/g, "");
    if (res[0] >= '0' && res[0] <= '9') {
        res = "_" + res;
    }
    return res;
}