import * as yup from "yup";
import i18next from "i18next";
import {uniq, mergeDeepLeft} from "ramda";
import {isPresent} from "ts-is-present";
import {parse, Options} from "acorn";
import {ValidateOptions, Message} from "yup/lib/types";

import {validateJsonSchema, isValidationFailure, validateUiSchema, validateUiSchemaLibrary, validate, compileSchema} from "./ajv";
import {ErrorInfo} from "./../models/error-info";
import {mapAjvErrors} from "../models/importers";
import {validateUiSchemaScopes} from "../utils/uischema-scope-validator";

/*eslint-disable no-new-func*/

//TODO: Evaluar migración a https://github.com/vriad/zod

const yupLocaleTemplate = {
    mixed: {
        default: "",
        required: "",
        oneOf: "",
        notOneOf: "",
        notType: "",
        //"defined": ""
    },
    string: {
        length: "",
        min: "",
        max: "",
        matches: "",
        email: "",
        url: "",
        //"uuid": "",
        trim: "",
        lowercase: "",
        uppercase: "",
    },
    number: {
        min: "",
        max: "",
        lessThan: "",
        moreThan: "",
        //"notEqual": "",
        positive: "",
        negative: "",
        integer: "",
    },
    date: {
        min: "",
        max: "",
    },
    object: {
        noUnknown: "",
    },
    array: {
        min: "",
        max: "",
    },
};

const buildLocale = (locale: string, obj: any, path: string[]): any => {
    const result: any = {};
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === "object") {
            path.push(key);
            result[key] = buildLocale(locale, value, path);
            path.pop();
        } else {
            result[key] = i18next.t(`${path.join(".")}.${key}`);
        }
    }
    return result;
};

const setYupLocale = (locale: string) => {
    const result = buildLocale(locale, yupLocaleTemplate, ["Yup"]);
    yup.setLocale(result);
};

const defaultAcornOptions: Options = {
    ecmaVersion: 2020,
};

const addJavascriptValidators = () => {
    yup.addMethod<yup.StringSchema>(
        yup.string,
        "jsExpression",
        function (options?: {paramNames?: string[]; scriptContextKey?: string}) {
            return this.test({
                test: function (value) {
                    if (typeof value === "string" && value.length > 0) {
                        try {
                            const paramNames = ["common"];
                            if (options?.paramNames) {
                                paramNames.push(...options.paramNames);
                            }
                            const scriptKey = options?.scriptContextKey;
                            let contextScript = scriptKey ? (this.options.context as any)?.[scriptKey] : undefined;
                            if (typeof contextScript === "function") {
                                contextScript = contextScript();
                            }
                            if (scriptKey && contextScript && typeof contextScript !== "string") {
                                return this.createError({
                                    path: this.path,
                                    message: `Yup context key '${scriptKey}' was expected to be of type string (or a function returning a string) but was ${typeof contextScript}`,
                                });
                            }
                            const script = `
                            function dummyfn(${paramNames.join(", ")}) {
                                return ${value};
                            };
                        `;
                            parse(script, {
                                ...defaultAcornOptions,
                                sourceType: "script",
                            });
                        } catch (e: any) {
                            return this.createError({
                                path: this.path,
                                message: e.toString(),
                            });
                        }
                    }
                    return true;
                },
            });
        }
    );

    yup.addMethod<yup.StringSchema>(yup.string, "jsScript", function (...paramNames: string[]) {
        return this.test({
            test: function (value) {
                if (typeof value === "string" && value.length > 0) {
                    try {
                        const script = `
                            function dummyfn(${paramNames.join(", ")}) {
                                ${value}
                            };
                        `;
                        parse(script, {
                            ...defaultAcornOptions,
                            sourceType: "script",
                        });
                    } catch (e: any) {
                        return this.createError({
                            path: this.path,
                            message: e.toString(),
                        });
                    }
                }
                return true;
            },
        });
    });

    yup.addMethod<yup.StringSchema>(yup.string, "jsModule", function () {
        return this.test({
            test: function (value) {
                if (typeof value === "string" && value.length > 0) {
                    try {
                        parse(value, {
                            ...defaultAcornOptions,
                            sourceType: "module",
                        });
                    } catch (e: any) {
                        return this.createError({
                            path: this.path,
                            message: e.toString(),
                        });
                    }
                }
                return true;
            },
        });
    });
};

const addJsonValidators = () => {
    const validateSchema = async (json: any, jsonSchemaContextKey: string, context: yup.TestContext) => {
        let jsonSchema = (context.options.context as any)?.[jsonSchemaContextKey];
        if (typeof jsonSchema === "function") {
            jsonSchema = jsonSchema();
        }
        if (typeof jsonSchema === "string") {
            jsonSchema = JSON.parse(jsonSchema);
        }

        if (jsonSchema) {
            const result = validate(jsonSchema, json);
            if (isValidationFailure(result)) {
                const einfos = mapAjvErrors(result.errors);
                if (einfos.length > 0) {
                    const errors = einfos.map(e => {
                        return context.createError({
                            path: context.path,
                            message: e.dataPath + " - " + e.message,
                        });
                    });
                    const aggregate = context.createError();
                    aggregate.inner = errors;
                    return aggregate;
                }
            }
        }
        return true;
    };

    yup.addMethod<yup.ObjectSchema<any>>(yup.object, "json", function (jsonSchemaContextKey: string) {
        return this.test({
            test: async function (value) {
                if (typeof value === "object") {
                    return await validateSchema(value, jsonSchemaContextKey, this);
                }
                return true;
            },
        });
    });

    yup.addMethod<yup.StringSchema>(yup.string, "json", function (jsonSchemaContextKey: string) {
        return this.test({
            test: async function (value) {
                if (typeof value === "string" && value) {
                    let json: any;
                    try {
                        json = JSON.parse(value);
                    } catch (error: any) {
                        return this.createError({
                            path: this.path,
                            message: error.toString(),
                        });
                    }
                    return await validateSchema(json, jsonSchemaContextKey, this);
                }
                return true;
            },
        });
    });
};

const addJsonSchemaValidators = () => {
    const validateSchema = (schema: object, context: yup.TestContext) => {
        try {
            const result = validateJsonSchema(schema);
            if (isValidationFailure(result)) {
                const errors = mapAjvErrors(result.errors).map(e => {
                    return context.createError({
                        path: context.path,
                        message: e.dataPath + " - " + e.message,
                    });
                });
                const aggregate = context.createError();
                aggregate.inner = errors;
                return aggregate;
            } else {
                compileSchema(schema, false);
            }
            return true;
        } catch (e: any) {
            return context.createError({
                path: context.path,
                message: e.message,
            });
}
    };

    yup.addMethod<yup.ObjectSchema<any>>(yup.object, "jsonSchema", function () {
        return this.test({
            test: function (value) {
                if (typeof value === "object") {
                    return validateSchema(value, this);
                }
                return true;
            },
        });
    });

    yup.addMethod<yup.StringSchema>(yup.string, "jsonSchema", function () {
        return this.test({
            test: function (value) {
                if (typeof value === "string" && value) {
                    let schema: any;
                    try {
                        schema = JSON.parse(value);
                    } catch (error: any) {
                        return this.createError({
                            path: this.path,
                            message: error.toString(),
                        });
                    }
                    return validateSchema(schema, this);
                }
                return true;
            },
        });
    });

    yup.addMethod<yup.StringSchema>(yup.string, "jsonSchemaDefinitionName", function (jsonSchemaContextKey: string) {
        return this.test({
            test: function (value) {
                if (jsonSchemaContextKey && typeof value === "string" && value.length > 0) {
                    try {
                        let jsonSchema = (this.options.context as any)?.[jsonSchemaContextKey];
                        if (typeof jsonSchema === "function") {
                            jsonSchema = jsonSchema();
                        }
                        if (typeof jsonSchema === "string") {
                            jsonSchema = JSON.parse(jsonSchema);
                        }
                        if (jsonSchema && typeof jsonSchema !== "object") {
                            return this.createError({
                                path: this.path,
                                message: `Yup context key '${jsonSchemaContextKey}' does not reference a valid json schema or a function returning one`,
                            });
                        }
                        const definition = jsonSchema.definitions?.[value];
                        if (typeof definition === "boolean") {
                            return true;
                        }
                        if (!isPresent(definition)) {
                            return this.createError({
                                path: this.path,
                                message: i18next.t("NoDefinitionInSchema", {name: value}),
                            });
                        }
                        if (typeof definition !== "object") {
                            return this.createError({
                                path: this.path,
                                message: `Json Schema definition '${value}' was expected to be of type object or boolean but was ${typeof jsonSchema}`,
                            });
                        }
                    } catch (e: any) {
                        return this.createError({
                            path: this.path,
                            message: e.toString(),
                        });
                    }
                }
                return true;
            },
        });
    });
};

const addUiSchemaValidators = () => {
    const validateSchema = async (schema: any, jsonSchemaContextKey: string, context: yup.TestContext) => {
        const result = validateUiSchema(schema);
        const einfos: ErrorInfo[] = [];
        if (isValidationFailure(result)) {
            einfos.push(...mapAjvErrors(result.errors));
        }
        let jsonSchema = (context.options.context as any)?.[jsonSchemaContextKey];
        if (typeof jsonSchema === "function") {
            jsonSchema = jsonSchema();
        }
        if (typeof jsonSchema === "string") {
            jsonSchema = JSON.parse(jsonSchema);
        }

        if (jsonSchema) {
            try {
                einfos.push(...(await validateUiSchemaScopes(schema, jsonSchema)));
            } catch (e: any) {
                if (einfos.length === 0) {
                    // Priorize schema errors.
                    return context.createError({
                        path: context.path,
                        message: e.toString(),
                    });
                }
            }
        }
        if (einfos.length > 0) {
            const errors = einfos.map(e => {
                return context.createError({
                    path: context.path,
                    message: e.dataPath + " - " + e.message,
                });
            });
            const aggregate = context.createError();
            aggregate.inner = errors;
            return aggregate;
        }
        return true;
    };

    yup.addMethod<yup.ObjectSchema<any>>(yup.object, "uiSchema", function (jsonSchemaContextKey: string) {
        return this.test({
            test: async function (value) {
                if (typeof value === "object") {
                    return await validateSchema(value, jsonSchemaContextKey, this);
                }
                return true;
            },
        });
    });

    yup.addMethod<yup.StringSchema>(yup.string, "uiSchema", function (jsonSchemaContextKey: string) {
        return this.test({
            test: async function (value) {
                if (typeof value === "string" && value) {
                    let schema: any;
                    try {
                        schema = JSON.parse(value);
                    } catch (error: any) {
                        return this.createError({
                            path: this.path,
                            message: error.toString(),
                        });
                    }
                    return await validateSchema(schema, jsonSchemaContextKey, this);
                }
                return true;
            },
        });
    });
};

const addUiSchemaLibraryValidators = () => {
    const validateSchema = async (schema: any, jsonSchemaContextKey: string, context: yup.TestContext) => {
        const result = validateUiSchemaLibrary(schema);
        const einfos: ErrorInfo[] = [];
        if (isValidationFailure(result)) {
            einfos.push(...mapAjvErrors(result.errors));
        }
        let jsonSchema = (context.options.context as any)?.[jsonSchemaContextKey];
        if (typeof jsonSchema === "function") {
            jsonSchema = jsonSchema();
        }
        if (typeof jsonSchema === "string") {
            jsonSchema = JSON.parse(jsonSchema);
        }

        if (jsonSchema) {
            try {
                einfos.push(...(await validateUiSchemaScopes(schema, jsonSchema)));
            } catch (e: any) {
                if (einfos.length === 0) {
                    // Priorize schema errors.
                    return context.createError({
                        path: context.path,
                        message: e.toString(),
                    });
                }
            }
        }
        if (einfos.length > 0) {
            const errors = einfos.map(e => {
                return context.createError({
                    path: context.path,
                    message: e.dataPath + " - " + e.message,
                });
            });
            const aggregate = context.createError();
            aggregate.inner = errors;
            return aggregate;
        }
        return true;
    };

    yup.addMethod<yup.ObjectSchema<any>>(yup.object, "uiSchemaLibrary", function (jsonSchemaContextKey: string) {
        return this.test({
            test: async function (value) {
                if (typeof value === "object") {
                    return await validateSchema(value, jsonSchemaContextKey, this);
                }
                return true;
            },
        });
    });

    yup.addMethod<yup.StringSchema>(yup.string, "uiSchemaLibrary", function (jsonSchemaContextKey: string) {
        return this.test({
            test: async function (value) {
                if (typeof value === "string" && value) {
                    let schema: any;
                    try {
                        schema = JSON.parse(value);
                    } catch (error: any) {
                        return this.createError({
                            path: this.path,
                            message: error.toString(),
                        });
                    }
                    return await validateSchema(schema, jsonSchemaContextKey, this);
                }
                return true;
            },
        });
    });
};

const addArrayUniquenessValidators = () => {
    yup.addMethod<yup.ArraySchema<any>>(yup.array, "unique", function (message: Message<{duplicates: any[]}>) {
        return this.test("unique", "", function (value: any) {
            const list = value as any[];
            if (!list || list.length === 0) {
                return true;
            }
            const dups: any[] = [];
            list.forEach((item, i) => {
                for (let j = i + 1; j < list.length; j++) {
                    if (item === list[j]) {
                        dups.push(item);
                    }
                }
            });
            if (dups.length > 0) {
                return this.createError({path: this.path, message, params: {duplicates: uniq(dups)}});
            }
            return true;
        });
    });
    yup.addMethod<yup.ArraySchema<any>>(
        yup.array,
        "uniqueBy",
        function (fn: (value: any) => any, message: Message<{duplicates: any[]}>) {
            return this.test("uniqueBy", "", function (value: any) {
                const list = value as any[];
                if (!list || list.length === 0) {
                    return true;
                }
                const dups: any[] = [];
                list.forEach((item, i) => {
                    const itemValue = fn(item);
                    for (let j = i + 1; j < list.length; j++) {
                        const testValue = fn(list[j]);
                        if (itemValue === testValue) {
                            dups.push(itemValue);
                        }
                    }
                });
                if (dups.length > 0) {
                    return this.createError({path: this.path, message, params: {duplicates: uniq(dups)}});
                }
                return true;
            });
        }
    );
};

const addAtLeastOneRequired = () => {
    yup.addMethod<yup.ObjectSchema<any>>(
        yup.object,
        "atLeastOneRequired",
        function (fieldNames: string[], message?: Message) {
            message = message ?? i18next.t("AtLeastOneRequired", {fieldNames: fieldNames.join(", ")}) ?? "";
            return this.test("atLeastOneRequired", message, function (obj: any) {
                if (!fieldNames || fieldNames.length === 0) {
                    return true;
                }
                if (!isPresent(obj)) {
                    return false;
                }
                if (fieldNames.some(s => isPresent(obj[s]))) {
                    return true;
                }
                return this.createError({
                    path: this.path ? `${this.path}.${fieldNames[0]}` : fieldNames[0],
                    message: i18next.t("AtLeastOneRequired", {fieldNames: fieldNames.join(", ")}),
                });
            });
        }
    );
};

const addWithLocalContext = () => {
    yup.addMethod<yup.BaseSchema<any>>(
        yup.mixed,
        "withLocalContext",
        function (fn: (schema: yup.BaseSchema<any>) => yup.BaseSchema<any>) {
            return fn(this);
        }
    );
};

const iso8601Regex =
    /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z|[+-][01][0-9]:[0-9][0-9])?$/;

const addIso8601FormattedString = () => {
    yup.addMethod<yup.StringSchema>(yup.string, "iso8601Formatted", function (message?: Message<{value: string}>) {
        return this.test("iso8601Formatted", "", function (val: any) {
            const value = val as string;
            if (!value) {
                return true;
            }
            if (!iso8601Regex.test(value)) {
                const defaultedMsg = (message =
                    message ?? i18next.t("ValueDoesNotComplyWithIso8601DateTimeStandard", {value}) ?? "");
                return this.createError({path: this.path, message: defaultedMsg});
            }
            return true;
        });
    });
};

const identifierRegex = /^[a-zA-Z_][a-zA-Z0-9_-]*$/;

const addIdentifier = () => {
    yup.addMethod<yup.StringSchema>(yup.string, "identifier", function (message?: Message<{value: string}>) {
        return this.test("identifier", "", function (val: any) {
            const value = val as string;
            if (!value) {
                return true;
            }
            if (!identifierRegex.test(value)) {
                const defaultedMsg = message ?? i18next.t("ValueIsNotAnIdentifier", {value}) ?? "";
                return this.createError({path: this.path, message: defaultedMsg});
            }
            return true;
        });
    });
};

const addYupErrors = (errors: yup.ValidationError[], errorInfos: ErrorInfo[], basePath?: string) => {
    for (const e of errors) {
        if (e.inner && e.inner.length > 0) {
            addYupErrors(e.inner, errorInfos, basePath);
        } else {
            errorInfos.push({dataPath: (basePath ?? "") + (e.path ?? ""), message: e.message});
        }
        /*if (e.inner && e.inner.length > 0) {
            addYupErrors(e.inner, messages, basePath);
        }*/
    }
};

export const extractYupErrorMessages = (
    errors: yup.ValidationError | yup.ValidationError[],
    basePath?: string
): ErrorInfo[] => {
    const result: ErrorInfo[] = [];
    errors = Array.isArray(errors) ? errors : [errors];
    addYupErrors(errors, result, basePath);
    return result;
};

export const validateWithYupSchema = async (
    schema: yup.BaseSchema<any> | undefined,
    value: any,
    options?: ValidateOptions<any>
): Promise<ErrorInfo[]> => {
    if (!schema) {
        return [] as ErrorInfo[];
    }
    const result = await schema
        .validate(value, mergeDeepLeft(options ?? {}, {abortEarly: false, strict: true}))
        .then(() => [] as ErrorInfo[])
        .catch((e: yup.ValidationError) => {
            return extractYupErrorMessages(e);
        });
    return result;
};

export const setupYup = () => {
    setYupLocale(i18next.language);
    i18next.on("languageChanged", s => setYupLocale(s));
    addJavascriptValidators();
    addJsonSchemaValidators();
    addJsonValidators();
    addUiSchemaValidators();
    addUiSchemaLibraryValidators();
    addArrayUniquenessValidators();
    addAtLeastOneRequired();
    addWithLocalContext();
    addIso8601FormattedString();
    addIdentifier();
};
