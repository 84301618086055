import {JSONSchema7} from "json-schema";
import {UISchemaElement} from "@jsonforms/core";
import jsonPointer from "json-pointer";
import i18next from "i18next";
import {isPresent} from "ts-is-present";

import {ErrorInfo} from "./../models/error-info";
import {depthFirstSearch} from "./graph-algorithms";
import {dereferenceSchema} from "../libs/json-schema-ref-parser";

interface ScopeData {
    scope: any;
    schemaPath: string;
    subscopes: ScopeData[];
}

//Assume any object containing a key "scope" is scopable.
const isScopable = (value: any): value is Record<string, any> => value && typeof value === "object" && "scope" in value;
const hasNestedScopes = (value: any) => isScopable(value) && (value.type === "Control" || value.type === "Use");

const buildScopeData = (scope: string, path: Array<string | number>) => {
    const dataPath =
        path
            .map(s => {
                if (typeof s === "number") {
                    return `[${s}]`;
                } else if (s.length > 0) {
                    return `.${s}`;
                }
                return "";
            })
            .join("") + ".scope";
    const result: ScopeData = {
        scope,
        schemaPath: dataPath,
        subscopes: [],
    };
    return result;
};

const collectScopes = (uischema: any) => {
    const scopes: ScopeData[] = [];
    const currentPath: Array<string | number> = [];
    const nestedScopeStack: ScopeData[] = [];
    const seen: any[] = [];

    depthFirstSearch<[string | number, any]>(["", uischema as any], {
        getNeighbors: v => {
            const value = v[1];
            if (Array.isArray(value)) {
                return value.map((s, i) => [i, s]);
            }
            if (typeof value === "object" && value !== null) {
                const entries = Object.entries(value);
                //Always process "type" property first.
                const idx = entries.findIndex(([key]) => key === "type");
                if (idx > 0) {
                    const tmp = entries[idx];
                    entries[idx] = entries[0];
                    entries[0] = tmp;
                }
                return entries;
            }
            return [];
        },
        enterVertex: ({currentVertex: [key, value]}) => {
            currentPath.push(key);
            if (isScopable(value)) {
                const scope = buildScopeData(value.scope, currentPath);
                if (hasNestedScopes(value)) {
                    nestedScopeStack.push(scope);
                }
                const stackLength = nestedScopeStack.length;
                const subscopes = stackLength > 1 ? nestedScopeStack[stackLength - 2].subscopes : scopes;
                subscopes.push(scope);
            }
        },
        leaveVertex: ({currentVertex: [, value]}) => {
            if (hasNestedScopes(value)) {
                nestedScopeStack.pop();
            }
            currentPath.pop();
        },
        allowTraversal: ({nextVertex: [key, value]}) => {
            if (seen.includes(value)) {
                return false;
            }
            seen.push(value);
            return true;
        },
    });
    return scopes;
};

const validateScope = (
    scopeData: ScopeData,
    schema: JSONSchema7,
    rootSchema: JSONSchema7,
    errors: ErrorInfo[]
) => {
    const e = (message: string) => {
        errors.push({
            dataPath: scopeData.schemaPath,
            message,
        });
    };

    const scope = scopeData.scope;
    if (typeof scope !== "string") {
        e(i18next.t("UiSchemaScopesMustBeOfTypeString"));
        return;
    }
    if (scope.length === 0) {
        e(i18next.t("UiSchemaScopesCannotBeEmpty"));
        return;
    }

    let curSchema = schema as any;
    if (scope !== "#") {
        if (!scope.startsWith("#/")) {
            e(i18next.t("UiSchemaScopesMustReferenceTheAssociatedSchema"));
            return;
        }
        // TODO: implements validation when using combinators (anyOf, allOf or oneOf) and additionalProperties.
        const pointer = scope.substr(1);
        const tokens = jsonPointer.parse(pointer);
        let isPropertiesKey = false;
        let parentIsPropertiesKey = false;
        for (let i = 0; i < tokens.length; i++) {
            const token = tokens[i];
            const nextSchema = curSchema[token];
            if (!isPresent(nextSchema)) {
                return e(
                    i18next.t("UiSchemaScopesWithInvalidPath", {pointer: jsonPointer.compile(tokens.slice(0, i + 1))})
                );
            }
            parentIsPropertiesKey = isPropertiesKey;
            isPropertiesKey = !parentIsPropertiesKey && token === "properties";
            curSchema = nextSchema;
        }
        if (!parentIsPropertiesKey) {
            return e(i18next.t("UiSchemaScopesDoesNotPointToAProperty", {pointer}));
        }
    }
    if (curSchema.type === "array") {
        curSchema = curSchema.items;
    }
    for (const subscope of scopeData.subscopes) {
        validateScope(subscope, curSchema, rootSchema, errors);
    }
    return undefined;
};

export const validateUiSchemaScopes = async (
    uischema: UISchemaElement,
    dataSchema: JSONSchema7
): Promise<ErrorInfo[]> => {
    const scopes = collectScopes(uischema);
    const dereferencedSchema = await dereferenceSchema(dataSchema as any);
    const errors: ErrorInfo[] = [];
    for (const scope of scopes) {
        validateScope(scope, dereferencedSchema, dereferencedSchema, errors);
    }
    return errors;
};
