import {ErrorObject} from "ajv";
import draft7MetaSchema from "ajv/dist/refs/json-schema-draft-07.json";
import addFormats from "ajv-formats";
import {DataValidationCxt} from "ajv/dist/types";
//import mapper_en from "ajv-i18n/localize/en";
import mapper_en from "./ajv/i18n/en";
import i18next from "i18next";
import {clone, omit} from "ramda";

import contextTypeSchema from "../schemas/context-type.schema.json";
import simulationStateSchema from "../schemas/simulation-state.schema.json";
import uiSchemaSchema from "../schemas/ui-schema.schema.json";
import uiSchemaLibrarySchema from "../schemas/ui-schema-library.schema.json";
import widgetDefinitionSchema from "../schemas/widget-definition.schema.json";
import dashboardDefinitionSchema from "../schemas/dashboard-definition.schema.json";
import recognizedImageBatchSchema from "../schemas/recognized-image-batch.schema.json";
import udFunctionSchema from "../schemas/ud-function-schema.json";
import collectionDefinitionSchema from "../schemas/collection-definition.schema.json";
import {NfAjv2019} from "./ajv/nf-ajv";

let mapper = mapper_en;

function setLanguage(lang: string) {
    //import(`ajv-i18n/localize/${lang}/index`)
    import(`./ajv/i18n/${lang}`)
        .then(s => {
            if (typeof s?.default === "function") {
                mapper = s.default;
            }
        })
        .catch(() => {
            const idx = lang.indexOf("-");
            if (idx > 0) {
                setLanguage(lang.substring(0, idx));
            }
        });
}

if (i18next.language) {
    setLanguage(i18next.language);
}

i18next.on("languageChanged", lang => {
    setLanguage(lang);
});

export const ajv = new NfAjv2019({
    allErrors: true,
    addUsedSchema: false,
    allowUnionTypes: true,
    strict: "log",
});
ajv.addMetaSchema(omit(["default"], draft7MetaSchema));
addFormats(ajv);
ajv.addFormat("markdown", () => true);
ajv.addFormat("nf-expression", () => true);
// NJsonSchema non-standard formats
ajv.addFormat("time-span", () => true);
ajv.addFormat("guid", () => true);
ajv.addFormat("int32", () => true);
ajv.addFormat("int64", () => true);
ajv.addFormat("float", () => true);
ajv.addFormat("double", () => true);
ajv.addFormat("decimal", () => true);
ajv.addFormat("byte", () => true);
ajv.addFormat("binary", () => true);
ajv.addFormat("phone", () => true);

export interface SchemaValidationSuccess {
    type: "success";
}

export interface SchemaValidationFailure {
    type: "failure";
    errors: ErrorObject[];
}

export type SchemaValidationResult = SchemaValidationSuccess | SchemaValidationFailure;

export const isValidationFailure = (result: SchemaValidationResult): result is SchemaValidationFailure => {
    return result.type === "failure";
};

export type SchemaValidationFn = (data: any, dataCxt?: DataValidationCxt) => SchemaValidationResult;

export const compileSchema = (schema: object, addSchema: boolean = false) => {
    if (addSchema) {
        ajv.addSchema(schema);
    }
    const validator = ajv.compile(schema);
    return (data: any, dataCxt?: DataValidationCxt): SchemaValidationResult => {
        const valid = validator(clone(data), dataCxt);
        if (!valid) {
            if (validator.errors && mapper) {
                mapper(validator.errors);
            }
            return {
                type: "failure",
                errors: validator.errors ?? [],
            };
        }
        return {type: "success"};
    };
};

export const validate = (schemaKeyRef: object | boolean, data: any): SchemaValidationResult => {
    const valid = ajv.validate(schemaKeyRef, data);
    if (typeof valid !== "boolean") {
        throw new Error("No $async validation allowed");
    }
    if (!valid) {
        if (ajv.errors && mapper) {
            mapper(ajv.errors);
        }
        return {
            type: "failure",
            errors: ajv.errors ?? [],
        };
    }
    return {type: "success"};
};

export const validateJsonSchema = (schema: object): SchemaValidationResult => {
    const valid = ajv.validateSchema(schema);
    if (valid) {
        //ajv.compile(schema);
        return {type: "success"};
    }
    if (ajv.errors && mapper) {
        mapper(ajv.errors);
    }
    return {
        type: "failure",
        errors: ajv.errors ?? [],
    };
};

export const translateValidationErrors = (errors: ErrorObject[]) => {
    if (mapper) {
        mapper(errors);
    }
    return errors;
};

//Force internal schemas compilation (and caching) in the correct order.
export const validateUiSchema = compileSchema(uiSchemaSchema, true);
export const validateUiSchemaLibrary = compileSchema(uiSchemaLibrarySchema, true);
export const validateContextTypeSchema = compileSchema(contextTypeSchema, true);
export const validateSimulationStateSchema = compileSchema(simulationStateSchema, true);
export const validateWidgetDefinitionSchema = compileSchema(widgetDefinitionSchema, true);
export const validateDashboardDefinitionSchema = compileSchema(dashboardDefinitionSchema, true);
export const validateRecognizedImageBatchSchema = compileSchema(recognizedImageBatchSchema, true);
export const validateUserDefinedFunctionSchema = compileSchema(udFunctionSchema, true);
export const validateCollectionDefinitionSchema = compileSchema(collectionDefinitionSchema, true);
