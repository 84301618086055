import {groupBy, omit} from "ramda";
import $RefParser, {ResolverOptions} from "@apidevtools/json-schema-ref-parser";
import {JSONSchema7} from "json-schema";
import {mapObjIndexed} from "ramda";

import contextTypeSchema from "../schemas/context-type.schema.json";
import simulationStateSchema from "../schemas/simulation-state.schema.json";
import uiSchemaSchema from "../schemas/ui-schema.schema.json";
import uiSchemaLibrarySchema from "../schemas/ui-schema-library.schema.json";
import widgetDefinitionSchema from "../schemas/widget-definition.schema.json";
import dashboardDefinitionSchema from "../schemas/dashboard-definition.schema.json";
import recognizedImageBatchSchema from "../schemas/recognized-image-batch.schema.json";

const schemas = [
    contextTypeSchema,
    simulationStateSchema,
    uiSchemaSchema,
    uiSchemaLibrarySchema,
    widgetDefinitionSchema,
    dashboardDefinitionSchema,
    recognizedImageBatchSchema,
] as JSONSchema7[];

const schemaMap = mapObjIndexed(
    (s: JSONSchema7[]) => s[0],
    groupBy(s => s.$id!, schemas)
);

const resolver: Partial<ResolverOptions> = {
    order: 1,
    canRead: file => {
        return !!schemaMap[file.url];
    },
    read: file => {
        return omit([/*"$schema",*/ "$id"], schemaMap[file.url]);
    },
};

const options: $RefParser.Options = {
    resolve: {
        nimflow: resolver,
    },
};

export const dereferenceSchema = (schema: string | JSONSchema7): Promise<JSONSchema7> => {
    return $RefParser.dereference(schema, options) as Promise<JSONSchema7>;
};
